//防抖
let timer_debounce = null
export const debounce = function (func, wait) {
  if (timer_debounce) {
    clearTimeout(timer_debounce)
    timer_debounce = null
  }
  timer_debounce = setTimeout(() => {
    func.apply(this, arguments)
  }, wait)
}

//节流
let timer_throttle = null
export const throttle = function (func, wait) {
  if (!timer_throttle) {
    timer_throttle = setTimeout(() => {
      func.apply(this, arguments)
      timer_throttle = null
    }, wait)
  }
}
export default {
  throttle,
  debounce,
}
