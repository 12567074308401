<script>
import api from '@/command/api'
import apiTool from '@/command/apiTool'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import DrawerFormModal from '../../../components/DrawerFormModal'
import { getAction } from '@/command/netTool'
import { debounce } from '@/utils/prototype'

export default {
  name: 'anli',
  data() {
    return {
      ...api.command.getState(),
      showModal: false,
      detail: {},
    }
  },
  mounted() {
    this.initList()
  },
  methods: {
    initList() {
      api.command.getList.call(this, {
        url: '/farmCase/page',
        current: 1,
        paramsValue: {},
      })
    },
    getHeader() {
      return [
        {
          name: '名称',
          key: 'name',
          type: 'input',
        },
        {
          label: '',
          type: 'text',
        },
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'caseName',
          title: '名称',
          align: 'left',
          sorter: (a, b) => a.caseName.length - b.caseName.length,
        },
        {
          dataIndex: 'upDown',
          title: '状态',
          type: 'badge',
          onExport: (records) => {
            return ['上架', '下架'][records]
          },
          filters: [
            {
              text: '上架',
              value: '0',
            },
            {
              text: '下架',
              value: '1',
            },
          ],
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '上架' : '下架',
              color: data == 0 ? 'green' : 'red',
            }
          },
        },
        {
          dataIndex: 'a8',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          width: '200px',
          typeData: ({ records }) => {
            return [
              {
                name: records.upDown == 0 ? '下架' : '上架',
                onClick: () => {
                  api.command.edit.call(this, {
                    url: `/farmCase/${records.upDown == 0 ? 'down' : 'up'}?id=${records.id}`,
                  })
                },
              },
              {
                name: '关联商品',
                onClick: () => {
                  this.$router.push('/contentManagement/anliRelation?caseId=' + records.id)
                },
              },
              {
                name: '编辑',
                onClick: () => {
                  this.detail = records
                  this.showModal = true
                },
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认删除？',
                onClick: () =>
                  api.command.del.call(this, {
                    url: `/farmCase/delBatch`,
                    params: {
                      ids: [records.id],
                    },
                  }),
              },
            ]
          },
        },
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => {
            this.detail = {}
            this.showModal = true
          },
        },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: (data) => {
                let ids = data.map((e) => e.id)
                return api.command.del.call(this, {
                  url: '/farmCase/delBatch',
                  params: { ids },
                })
              },
            },
            {
              name: '批量上架',
              onClick: (data) => {
                let ids = data.map((e) => e.id)
                return api.command.edit.call(this, {
                  url: '/farmCase/upBatch',
                  params: { ids },
                })
              },
            },
            {
              name: '批量下架',
              onClick: (data) => {
                let ids = data.map((e) => e.id)
                return api.command.edit.call(this, {
                  url: '/farmCase/downBatch',
                  params: { ids },
                })
              },
            },
          ],
        },
      ]
    },
    getForm1() {
      return {
        title: '',
        type: 'cardForm',
        form: [
          {
            name: '主体名称',
            type: 'input',
            cols: 24,
            key: 'caseName',
            maxLength: 15,
            props: {
              maxLength: 15,
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            type: 'iconUpload',
            cols: 24,
            key: 'caseImgUrl',
            name: '封面图',
            rules: [
              {
                required: true,
                message: '请上传封面图',
              },
            ],
            typeData: [
              {
                maxLength: 1,
                key: 'caseImgUrl',
              },
            ],
          },
          {
            type: 'uploadVideo',
            cols: 24,
            key: 'caseVideo',
            name: '案例视频',
            rules: [
              {
                required: true,
                message: '请上传案例视频',
              },
            ],
            typeData: [
              {
                maxLength: 1,
                key: 'caseVideo',
              },
            ],
          },
        ].filter((e) => (e.display == undefined ? true : e.display)),
      }
    },
    getSumbitData() {
      const codeText = ['province', 'city', 'area']
      let arr = document.getElementsByClassName('ant-cascader-picker-label')[0].innerHTML.split('/')
      const obj = {}
      arr.forEach((e, i) => {
        obj[codeText[i] + 'Name'] = e
        obj[codeText[i] + 'Code'] = this.detail.dwCode[i]
      })
      return obj
    },
    onSubmit(data) {
      let obj = {
        ...data,
      }
      debounce(() => {
        api.command[this.detail.id ? 'edit' : 'create']
          .call(this, {
            url: `/farmCase/${this.detail.id ? 'edit' : 'save'}`,
            params: {
              ...obj,
            },
            isPost: false,
          })
          .then(() => {
            this.showModal = false
            this.initList()
          })
      }, 800)
    },
  },
  render() {
    return (
      <div>
        <TemplateHeaderTable
          headerData={this.getHeader()}
          tableColumns={this.getColumns()}
          button={this.getButton()}
          records={this.records}
        />
        <DrawerFormModal
          visible={this.showModal}
          title="基本信息"
          width={'800px'}
          onClose={() => (this.showModal = false)}
          onSubmit={this.onSubmit}
          data={[this.getForm1()]}
          form={this.detail}
        ></DrawerFormModal>
      </div>
    )
  },
}
</script>
